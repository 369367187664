import type { DataRecordModelOutput } from "@/apis/services/HazardService";
import { ClimateScenario, RiskClassEnum } from "@/apis/services/HazardService";
import { RISK_RATINGS_CLIMATE_SCENARIO } from "@/constants/informativeTexts";

export const POLL_TIMEOUT_IN_MS = 20000;
export const POLL_TIMEOUT_IN_MS_FOR_RASTER = 2000;

export const FUNCTIONAL_DOWNTIME_GRAPH_COLORS = [
  "#539684",
  "#AE1919",
  "#D39932",
  "#1395BA",
  "#4F0B4E",
  "#BF3B23",
];

export function sortOnCreatedDate<T>(input: T[]): T[] {
  return input?.sort(
    (a: any, b: any) =>
      Date.parse(b.created_date || "January 1, 1970") -
      Date.parse(a.created_date || "0")
  );
}

export const RISK_CLASS_LABELS: any = {
  [RiskClassEnum.Value0]: "Class 0 - Prescreening",
  [RiskClassEnum.Value1]: "Class 1 - Risk Screening",
  [RiskClassEnum.Value2]: "Class 2 - Enhanced Risk Screening",
  [RiskClassEnum.Value3]: "Class 3 - Site Specific Risk Modelling",
  [RiskClassEnum.Value4]: "Class 4 - Detailed Site Specific Risk Modelling",
};

export const SUPPORTED_PERCENTILES = [0, 50];

export const CLIMATE_SCENARIO_LOOKUP: {
  [key in ClimateScenario]: ClimateScenario;
} = {
  [ClimateScenario.Current]: ClimateScenario.Current,
  [ClimateScenario.RCP1P9]: ClimateScenario.SSP11P9,
  [ClimateScenario.SSP11P9]: ClimateScenario.SSP11P9,
  [ClimateScenario.RCP2P6]: ClimateScenario.SSP12P6,
  [ClimateScenario.SSP12P6]: ClimateScenario.SSP12P6,
  [ClimateScenario.RCP4P5]: ClimateScenario.SSP24P5,
  [ClimateScenario.SSP24P5]: ClimateScenario.SSP24P5,
  [ClimateScenario.RCP7P0]: ClimateScenario.SSP37P0,
  [ClimateScenario.SSP37P0]: ClimateScenario.SSP37P0,
  [ClimateScenario.RCP8P5]: ClimateScenario.SSP58P5,
  [ClimateScenario.SSP58P5]: ClimateScenario.SSP58P5,
  [ClimateScenario.RCP3P4]: ClimateScenario.RCP3P4,
  [ClimateScenario.RCP6P0]: ClimateScenario.RCP6P0,
};

export type FutureRiskRatingData = {
  [key in ClimateScenario]: {
    shortTerm: DataRecordModelOutput[];
    midCentury: DataRecordModelOutput[];
    endOfCentury: DataRecordModelOutput[];
  };
};
export const RISK_RATINGS_TABLE_HEADER = {
  shortTerm: RISK_RATINGS_CLIMATE_SCENARIO.SHORT_TERM,
  midCentury: RISK_RATINGS_CLIMATE_SCENARIO.MID_OF_CENTURY,
  endOfCentury: RISK_RATINGS_CLIMATE_SCENARIO.END_OF_CENTURY,
};
